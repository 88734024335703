const CheckedIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 2.67857C4.61334 2.67857 2.67857 4.61334 2.67857 7C2.67857 9.38666 4.61334 11.3214 7 11.3214C9.38666 11.3214 11.3214 9.38666 11.3214 7C11.3214 6.38431 11.193 5.80017 10.9621 5.27165C10.8318 4.97342 10.9679 4.62602 11.2662 4.49572C11.5644 4.36542 11.9118 4.50155 12.0421 4.79978C12.3368 5.47431 12.5 6.21887 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C8.08009 1.5 9.08912 1.81194 9.93982 2.35087C10.2147 2.52504 10.2964 2.88911 10.1223 3.16403C9.94808 3.43896 9.58401 3.52064 9.30909 3.34647C8.64148 2.92352 7.85024 2.67857 7 2.67857Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.80788 4.76967C10.0555 5.00393 10.065 5.39323 9.82923 5.63921L6.72288 8.87976C6.44339 9.17133 5.976 9.17372 5.6935 8.88503L4.17519 7.33345C3.93687 7.0899 3.94239 6.70051 4.18752 6.46373C4.43266 6.22695 4.82458 6.23243 5.0629 6.47598L6.20175 7.63979L8.93268 4.79087C9.16846 4.5449 9.56031 4.53541 9.80788 4.76967Z'
      />
    </svg>
  );
};

export { CheckedIcon };
